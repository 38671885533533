import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: { 
        translation: {
            nav: {
              name: 'Minute Gallery Design',
              dropdown: {
                home: 'Home',
                about: 'About',
                clients: 'Our Clients',
                services: 'Services',
                contact: 'Contact'
              }
            },
            contactForm: {
              label: 'Book a free consultation',
              placeholder: '+1 (438) 830-6898',
              doneMessage: 'We will be in touch soon.'
            },
            footer: {
              location: 'Based in Montreal, Canada.'
            },
            home: {
              title: {
                line_1: 'Web Design',
                line_2: 'For Watch and Jewellery Stores.'
              }
            },
            about: {
              title: 'About us',
              content: {
                p1: 'At Minute Gallery, we are passionate artisans of digital elegance, dedicated to crafting exquisite online experiences for jewellery boutiques and watch retailers. ',
                p2: 'Based in Montreal with a deep-rooted appreciation for the timeless allure of luxury goods and a keen eye for modern design trends, we are committed to helping our clients unlock the full potential of their brands in the digital realm.'
              }
            },
            clients: {
              title: 'Who we work with',
              content: {
                p1: 'Our mission is to help jewellery store owners and watch store owners from Montreal establish an online presence. We’re here to let customers find the city’s hidden gems.',
                p2: 'Are you one of them?'
              }
            },
            services: {
              title: 'Our Service',
              subtitle: 'A custom design that matches your brand.',
              content: {
                p1: 'Partnering with us yields a bespoke website tailored to your store’s ambiance. We understand that your establishment holds its own distinct charm and allure, and our offer is to seamlessly translate that essence into the digital space.',
                p2: 'We abstract away the technicalities of SEO, mobile optimization, and custom integrations. We take care, so you don\’t have to.'
              }
            },
            contact: {
              title: 'Contact',
              subtitle: 'We\'re always here to assist you.',
              label: {
                phone: 'Phone',
                email: 'Email',
                social: 'Social Media'
              },
              field: {
                phone: '+1 (438) 830-6898',
                email: 'contact@minutegallery.design',
                instagram: '@minute.gallery'
              }
            }
        }
      },
      fr: {
        translation: {
          nav: {
            name: 'Minute Gallerie Design',
            dropdown: {
              home: 'Accueil',
              about: 'À propos',
              clients: 'Nos Clients',
              services: 'Services',
              contact: 'Contact'
            }
          },
          contactForm: {
            label: 'Obtenez une consultation gratuite',
            placeholder: '+1 (438) 830-6898',
            doneMessage: 'Nous prendrons contact bientôt.'
          },
          footer: {
            location: 'Originaire de Montréal, Québec.'
          },
          home: {
            title: {
              line_1: 'Design Web',
              line_2: 'Pour fine joaillerie et haute horlogerie.'
            }
          },
          contact: {
            title: 'Contact',
            subtitle: 'Nous sommes là pour vous.',
            label: {
              phone: 'Téléphone',
              email: 'Email',
              social: 'Médias Sociaux'
            },
            field: {
              phone: '+1 (438) 830-6898',
              email: 'contact@minutegallery.design',
              instagram: '@minute.gallery'
            }
          }
        }
        
      },
    }
  });

export default i18n;