import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ContactForm from '../Form/ContactForm';

function Footer() {
    const { t } = useTranslation();
    const location = useLocation();
    
    return ( 
        <>
            {   
                location.pathname === '/' || location.pathname === '' ? 
                <div className='grow-0 w-full md:h-[15dvh] mb-4 mt-4 sm:20 md:mb-0 px-[10%] lg:px-[15%] text-darkText text-lg lg:text-lg flex items-center'>
                    {t('footer.location')}
                </div>
                :
                <div className='grow-0 w-full md:h-[20dvh] sm:px-[10%] border-t-2 border-mediumLightBackground flex items-center justify-center mb-3 sm:mb-10'>
                    <div className='md:ml-10 mt-3'>
                        <ContactForm/>
                    </div>
                </div>
            }
        </>
    );
}

export default Footer;
