import React from 'react';
import { ReactComponent as Load } from '../../assets/logos/loading.svg';

function Loading() {
    return (
      <>
        <div className='h-[100dvh] w-[100dvw] flex items-center justify-center'>
            <Load className='w-10 h-10 animate-spin'/>
        </div>
      </>
    );
}

export default Loading;
