import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';

function ContactForm() {
    const form = useRef();
    const { t, i18n } = useTranslation();
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [ phoneNumber, setPhoneNumber ] = useState('')

    const sendEmail = (e) => {
        e.preventDefault();
        
        if(!phoneNumber) return;
        
        setIsEmailSent(true);
    
        emailjs
          .sendForm('service_b6vbysj', 'template_id2mxnc', form.current, {
            publicKey: 'Yp4uQHAPp9hPEm0t-',
          });
    };

    return ( 
        <>
            {
                !isEmailSent ? 
                <form ref={form}>
                    <label className='block text-mediumLightText text-xl pb-2'>{ t('contactForm.label') }</label>
                    <div className='flex'>
                        <input className='hidden' name='language' id="language" value={i18n.language} ></input>
                        <input className='w-60 h-20 border-none rounded-l-xl bg-mediumBackground text-mediumDarkText placeholder:text-mediumDarkText placeholder:text-center text-center focus:outline-darkText' type='tel' name='phone' id="phone" placeholder={t('contactForm.placeholder')} value={phoneNumber} onChange={(e)=> setPhoneNumber(e.target.value)}></input>
                        <div className='h-full w-2 bg-lightBackground'></div>
                        <button aria-label="Submit the form" type='submit' className='w-20 h-20 bg-darkBackground rounded-r-xl hover:scale-90 transition ease-in-out delay-150' onClick={(e) => sendEmail(e)}>
                            <div className='w-[40%] h-[0.2rem] mt-3 float-end bg-lightBackground'></div>
                        </button>
                    </div>
                </form>
                : 
                <p className='text-mediumLightText'>
                    {t('contactForm.doneMessage')}
                </p>
            }
        </>
    );
}

export default ContactForm;