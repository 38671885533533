import React from 'react';
import ReactDOM from 'react-dom/client';
import '@coreui/coreui/dist/css/coreui.min.css'
import './index.css';
import './i18n'; // Assuming you have your i18n setup file
import reportWebVitals from './reportWebVitals';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <div className='bg-lightBackground'>
      <App />
    </div>
  </React.StrictMode>
);

reportWebVitals();
