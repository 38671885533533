import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from '../../assets/logos/logo.svg';
import { ReactComponent as FrenchFlag } from '../../assets/logos/french_flag.svg';
import { ReactComponent as BritishFlag } from '../../assets/logos/british_flag.svg';

function Navigation() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const [isDropdownOpen, setIsDropDownOpen] = useState(false);    

    const languageSelector = () => {
        const languages = ['en', 'fr'];
        const langToSwapTo = languages.filter(l => !i18n.language.includes(l))[0];

        if (langToSwapTo === 'fr') {
            return (
                <FrenchFlag className='w-8 h-8 ml-12 mr-12 cursor-pointer hover:scale-90 transition ease-in-out delay-150' onClick={() => i18n.changeLanguage(langToSwapTo)} />
            );
        }

        return (
            <BritishFlag className='w-8 h-8 ml-12 mr-12 cursor-pointer hover:scale-90 transition ease-in-out delay-150' onClick={() => i18n.changeLanguage(langToSwapTo)} />
        );
    }

    const dropDown = () => {
        return (
            <div onMouseEnter={ () => { setIsDropDownOpen(true) }} onMouseLeave={ () => { setIsDropDownOpen(false)} }>
                <Logo className='cursor-pointer hover:scale-90 transition ease-in-out delay-150' />
                
                <div className={`${isDropdownOpen ? "block" : "hidden"} absolute z-10 bg-darkBackground text-lightText py-4 space-y-2 rounded-xl ml-[-6rem] mt-0 cursor-pointer text-center`}>
                    <a className='block w-100 font-medium hover:bg-mediumLightBackground hover:text-darkText px-5 py-3' href="https://minutegallery.design/">{t('nav.dropdown.home')}</a>
                    {/* <a className='block w-100 font-medium hover:bg-mediumLightBackground hover:text-darkText px-5 py-3' href="https://minutegallery.design/About">{t('nav.dropdown.about')}</a>
                    <a className='block w-100 font-medium hover:bg-mediumLightBackground hover:text-darkText px-5 py-3' href="https://minutegallery.design/Clients" >{t('nav.dropdown.clients')}</a>
                    <a className='block w-100 font-medium hover:bg-mediumLightBackground hover:text-darkText px-5 py-3' href="https://minutegallery.design/Services" >{t('nav.dropdown.services')}</a> */}
                    <a className='block w-100 font-medium hover:bg-mediumLightBackground hover:text-darkText px-5 py-3' href="https://minutegallery.design/Contact" >{t('nav.dropdown.contact')}</a>
                </div>
            </div>
        );
    }

    return ( 
        <div className='grow-0 pt-16 sm:pt-28 px-[8%] sm:px-[10%] lg:px-[15%] flex flex-row items-center'>
            <div className='text-center'>
                <h2 className='font-semibold text-darkText text-sm sm:text-xl md:text-2xl lg:text-3xl'>MG | Design</h2>
                <h2 className='text-mediumLightText text-xs md:text-2xl lg:text-3xl'>{ t('nav.name') }</h2>
            </div>
            <div className='flex-grow'></div>
            <div></div>
            { languageSelector() }
            { dropDown() }
        </div>
    );
}

export default Navigation;
