// App.js
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './Components/Navigation/Navigation';
// import Carroussel from './Components/Carroussel/Carroussel';
import Footer from './Components/Footer/Footer';
import Loading from './Pages/Loading/Loading';

const HomePage = React.lazy(() => import('./Pages/Home/Home'));
const AboutPage = React.lazy(() => import('./Pages/About/About'));
const ClientsPage = React.lazy(() => import('./Pages/Clients/Clients'));
const ServicesPage = React.lazy(() => import('./Pages/Services/Services'));
const ContactPage = React.lazy(() => import('./Pages/Contact/Contact'));

function App() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <div className="flex h-[100dvh] w-[100dvw] flex-row">
          <div className='flex flex-col grow'>
            <Navigation />
            <div className='px-[10%] sm:px-20 lg:px-40 xl:px-60 2xl:px-[25%] flex-grow overflow-scroll overflow-x-hidden'>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/clients" element={<ClientsPage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/contact" element={<ContactPage />} />
              </Routes>
            </div>
            <Footer />
          </div>
          {/* <Carroussel/> */}
        </div>
      </Suspense>
    </Router>
  );
}

export default App;